import { useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";

import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Skeleton
} from "@mui/material";

import {
  tableReducer,
  dispatchFiltersChange,
  dispatchChangePage,
  dispatchChangeRowsPerPage,
  sortCardViewTable
} from "helpers/tables";

import { DEFAULT_TABLE_REDUCER_VALUES } from "constants/tables";

import GradeReportLink from "components/GradeReportLink";

import EnhancedTableHead from "components/Tables/EnhancedTableHead";
import CellGeneratedRow from "components/Tables/CellGeneratedRow";
import EnhancedPagination from "components/Tables/EnhancedPagination";
import NoResultsRow from "components/Tables/NoResultsRow";

import { CardViewRow } from "types/gameInfo";
import { CellGeneratedTableRow } from "types/tables";

interface CardViewTableProps {
  data: any[];
  isFetching: boolean;
}

function CardViewTable(props: CardViewTableProps) {
  const { data, isFetching } = props;

  const [count, setCount] = useState(0);

  const [variables, dispatch] = useReducer(tableReducer, {
    ...DEFAULT_TABLE_REDUCER_VALUES,
    orderBy: "finalGrade",
    limit: 25
  });

  const { page, limit, order, orderBy } = variables;

  useEffect(() => {
    if (!isFetching) {
      setCount(data?.length || 0);
    }
  }, [data, isFetching]);

  const isAuthentic = (grade: string) => grade === "Authentic";

  const HEAD_CELLS: CellGeneratedTableRow[] = [
    {
      id: "gradedId",
      label: "Grade #",
      display: ({ gradedId }: CardViewRow) => (
        <GradeReportLink gradedId={gradedId} />
      )
    },
    {
      id: "finalGrade",
      label: "Final",
      display: ({ finalGrade, grade }: CardViewRow) => (
        <span>
          {isAuthentic(grade) ? "Authentic" : `${finalGrade} (${grade})`}
        </span>
      )
    },
    {
      id: "centeringGrade",
      label: "Center",
      align: "center",
      display: ({ centeringGrade, grade }: CardViewRow) =>
        isAuthentic(grade) ? "" : centeringGrade
    },
    {
      id: "surfaceGrade",
      label: "Surface",
      align: "center",
      display: ({ surfaceGrade, grade }: CardViewRow) =>
        isAuthentic(grade) ? "" : surfaceGrade
    },
    {
      id: "cornersGrade",
      label: "Corners",
      align: "center",
      display: ({ cornersGrade, grade }: CardViewRow) =>
        isAuthentic(grade) ? "" : cornersGrade
    },
    {
      id: "edgesGrade",
      label: "Edges",
      align: "center",
      display: ({ edgesGrade, grade }: CardViewRow) =>
        isAuthentic(grade) ? "" : edgesGrade
    }
  ];

  const filteredData =
    data?.slice().sort(sortCardViewTable(order, orderBy)) || [];

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(_e: any, property: string) =>
              dispatchFiltersChange(
                { key: "sort", property, orderBy, order },
                dispatch
              )
            }
            headCells={HEAD_CELLS}
          />
          <TableBody>
            {isFetching ? (
              Array.from(Array(limit)).map((_e: any, i: number) => {
                const key = `empty_${i}`;
                return (
                  <TableRow key={key}>
                    <TableCell colSpan={HEAD_CELLS.length}>
                      <Skeleton height={28} />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <>
                {filteredData?.length > 0 ? (
                  filteredData
                    ?.slice(page * limit, page * limit + limit)
                    .map((row: any) => (
                      <CellGeneratedRow
                        key={`${row.gradedId}`}
                        row={row}
                        cells={HEAD_CELLS}
                      />
                    ))
                ) : (
                  <NoResultsRow
                    colSpan={HEAD_CELLS.length}
                    data-testid="no-results-display"
                  />
                )}
              </>
            )}
            <TableRow>
              <EnhancedPagination
                count={count}
                rowsPerPage={limit}
                page={count === 0 ? 0 : page}
                onChangePage={(newPage: number) =>
                  dispatchChangePage(newPage, limit, dispatch)
                }
                onChangeRowsPerPage={(rowsPerPage: number) =>
                  dispatchChangeRowsPerPage(rowsPerPage, dispatch)
                }
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default CardViewTable;
