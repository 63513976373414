import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  GameInfo,
  GameOption,
  CardInfo,
  CardGradesQueryResponse,
  CardGradeQueryResponse,
  CardGradeViewQueryResponse,
  CardViewQueryResponse,
  CardViewRow
} from "types/gameInfo";

import { filterCardsGraded } from "helpers/tables";

import { sanitizeURISlug } from "helpers/routing";

const API_SERVER = "https://app.premiercardgrading.com";
// const API_SERVER = "https://localdefault.fableforge.co"; // Dev

export const pcgPublicApi = createApi({
  reducerPath: "pcgPublicApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_SERVER}/public/api/v1/` }),
  endpoints: builder => ({
    getGameInfo: builder.query<GameInfo[], void>({
      query: () => `gameInfo?includeCounts=true`
    }),
    getSearchResults: builder.query<
      CardViewRow[],
      { queryString: string | undefined; offset: number; limit: number }
    >({
      query: ({ queryString, offset, limit }) =>
        `gradings?offset=${offset}&limit=${limit}${queryString}`
    }),
    getCardInfo: builder.query<
      CardInfo[],
      { gameCode: string | undefined; setName: string | undefined }
    >({
      query: ({ gameCode, setName }) =>
        `gameInfo/${sanitizeURISlug(gameCode)}/set/${sanitizeURISlug(
          setName
        )}?includeCounts=true`
    }),
    getCardGrades: builder.query<
      CardGradesQueryResponse,
      {
        gameCode: string | undefined;
        setName: string | undefined;
        number: string | undefined;
      }
    >({
      query: ({ gameCode, setName, number }) =>
        `gradings?gameCode=${sanitizeURISlug(
          gameCode
        )}&setName=${sanitizeURISlug(setName)}&cardNumber=${sanitizeURISlug(
          number
        )}&limit=1000000`
    }),
    getCardsGradesFinalGrade: builder.query<
      CardGradesQueryResponse,
      {
        gameCode: string | undefined;
        setName: string | undefined;
        number: string | undefined;
        finalGrade: string | undefined;
      }
    >({
      query: ({ gameCode, setName, number, finalGrade }) =>
        `gradings?gameCode=${sanitizeURISlug(
          gameCode
        )}&setName=${sanitizeURISlug(
          setName
        )}&cardNumber=${number}&finalGrade=${finalGrade}`
    }),
    getCardGrade: builder.query<
      CardGradeQueryResponse,
      { gradedId: string | undefined }
    >({
      query: ({ gradedId }) => `gradings?gradedId=${gradedId}`
    }),
    getGradedCardGrades: builder.query<
      CardInfo[],
      {
        gameCode: string | undefined;
        setName: string | undefined;
        number: string | undefined;
        finalGrade: number;
      }
    >({
      query: ({ gameCode, setName, number, finalGrade }) =>
        `gradings?gameCode=${sanitizeURISlug(
          gameCode
        )}&setName=${sanitizeURISlug(
          setName
        )}&cardNumber='${number}'&finalGrade=${finalGrade}`
    }),
    getGradeNames: builder.query<string[], void>({
      query: () => `gradeNames`
    })
  })
});

export const {
  useGetGameInfoQuery,
  useGetSearchResultsQuery,
  useGetCardInfoQuery,
  useGetCardGradesQuery,
  useGetCardGradeQuery,
  useGetGradedCardGradesQuery,
  useGetCardsGradesFinalGradeQuery,
  useGetGradeNamesQuery
} = pcgPublicApi;

export const useGetGameOptions = (): GameOption[] => {
  const { data, error, isLoading } = useGetGameInfoQuery();
  if (!isLoading && !error && data) {
    let names = data?.map(game => ({
      ...game,
      found: true
    }));
    return names;
  }
  return [];
};

interface SearchOptions {
  options: {
    [key: string]: {
      sets: string[];
      finish: string[];
    };
  };
  gradeNames: string[];
}

export const useGetSearchOptions = (): SearchOptions => {
  const { data, error, isLoading } = useGetGameInfoQuery();
  const {
    data: gradeNamesData,
    error: gradeNamesError,
    isLoading: gradeNamesIsLoading
  } = useGetGradeNamesQuery();

  if (
    !isLoading &&
    !gradeNamesIsLoading &&
    !error &&
    !gradeNamesError &&
    data &&
    gradeNamesData
  ) {
    return data?.reduce(
      (acc: any, row: any) => {
        acc.options[row.game] = {
          sets: row.sets
            .slice()
            .filter(filterCardsGraded(null))
            .map((set: any) => set.setName),
          finish: row.finish.map((finish: any) => finish.printing)
        };
        return acc;
      },
      {
        options: {},
        gradeNames: gradeNamesData
      }
    );
  }

  return {
    options: {},
    gradeNames: []
  };
};

export const useGetGameInfo = (gameCode: string | undefined): GameOption => {
  const { data, error, isLoading } = useGetGameInfoQuery();
  if (!isLoading && !error && data) {
    const result = data?.find(
      game => game.gameCode.toLowerCase() === gameCode?.toLowerCase()
    );
    if (result) {
      return {
        ...result,
        found: true
      };
    }
  }
  return {
    id: 0,
    game: "",
    gameCode: "",
    sets: [],
    finish: [],
    cardsGraded: null,
    gameImage: "",
    description: "",
    found: false
  };
};

export const useGetCardViewQuery = ({
  gameCode,
  setName,
  number
}: {
  gameCode: string;
  setName: string;
  number: string;
}): CardViewQueryResponse => {
  const { data, error, isLoading, isFetching } = useGetCardGradesQuery({
    gameCode,
    setName,
    number
  });
  if (!isLoading && !error && data) {
    const { totalElements, totalPages, data: resultsData } = data;
    if (resultsData && resultsData.length > 0) {
      const { cardInfo, cardName, cardNumber, setName } = resultsData[0];

      return {
        totalElements,
        totalPages,
        data: resultsData,
        error,
        isLoading,
        isFetching,
        cardInfo: cardInfo || {
          id: null,
          gameCode: gameCode,
          binderId: "",
          title: `${cardName} (${cardNumber})`,
          cardName: cardName,
          rarity: "",
          number: cardNumber,
          image: "",
          releaseDate: "",
          setName: setName,
          cardsGraded: null,
          finalGradeCounts: null,
          archived: false,
          reviewed: true
        }
      };
    }
  }
  return {
    totalElements: 0,
    totalPages: 0,
    data: null,
    error,
    isLoading,
    isFetching,
    cardInfo: null
  };
};

export const useGetGradeReportViewQuery = ({
  gradedId
}: {
  gradedId: string;
}): CardGradeViewQueryResponse => {
  const { data, error, isLoading, isFetching } = useGetCardGradeQuery({
    gradedId
  });
  if (!isLoading && !error && data) {
    const { totalElements, totalPages, data: resultsData } = data;
    if (resultsData && resultsData.length > 0) {
      return {
        totalElements,
        totalPages,
        data: resultsData[0] || null,
        error,
        isLoading,
        isFetching,
        cardInfo: resultsData[0].cardInfo
      };
    }
  }
  return {
    totalElements: 0,
    totalPages: 0,
    data: null,
    error,
    isLoading,
    isFetching,
    cardInfo: null
  };
};

export const useGetCardGradeViewQuery = ({
  gameCode,
  setName,
  number,
  finalGrade
}: {
  gameCode: string;
  setName: string;
  number: string;
  finalGrade: string;
}): CardViewQueryResponse => {
  const {
    data,
    error,
    isLoading,
    isFetching
  } = useGetCardsGradesFinalGradeQuery({
    gameCode,
    setName,
    number,
    finalGrade
  });
  if (!isLoading && !error && data) {
    const { totalElements, totalPages, data: resultsData } = data;
    if (resultsData && resultsData.length > 0) {
      return {
        totalElements,
        totalPages,
        data: resultsData,
        error,
        isLoading,
        isFetching,
        cardInfo: resultsData[0].cardInfo
      };
    }
  }
  return {
    totalElements: 0,
    totalPages: 0,
    data: null,
    error,
    isLoading,
    isFetching,
    cardInfo: null
  };
};
